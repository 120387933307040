<!--账户信息-->
<template>
  <div class="accountInfo">
    <div class="avatarInfo">
      <div class="avatarText avatarTextSize"><span>头像</span></div>
      <div class="avatarImg avatarTextSize">
        <img class="image"
             src="/img/cover_head.png" alt="">
<!--        <div class="modify" @click="getProfilePictureModal">修改</div>-->
      </div>
    </div>
    <div class="avatarInfo">
      <div class="avatarText"><span>会员等级</span></div>
      <div class="avatarImg gradeTextSize">
        <div class="avatarTitle">{{infoData.groupId}}</div>
      </div>
    </div>
<!--    <div class="avatarInfo">-->
<!--      <div class="avatarText"><span>昵称</span></div>-->
<!--      <div class="avatarImg gradeTextSize">-->
<!--        <div class="avatarTitle">微信用户</div>-->
<!--        <div class="modify" @click="getMOdifyName">修改</div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="avatarInfo">
      <div class="avatarText"><span>用户ID</span></div>
      <div class="avatarImg gradeTextSize">
        <div class="avatarTitle">{{infoData.user_id}}</div>
      </div>
    </div>
    <div class="avatarInfo">
      <div class="avatarText"><span>微信</span></div>
      <div class="avatarImg gradeTextSize">
        <div class="avatarTitle">已绑定</div>
        <div class="modify" @click="getChangeBinding">换绑</div>
      </div>
    </div>
    <!--修改昵称弹窗-->
    <client-only>
      <el-dialog footer-hide title="修改昵称" v-model="modifyNameModal" style="width: 500px">
        <div class="modalBox">
          <div>
            <el-input size="large" class="modifyNameTitle" v-model="modifyNameValue" placeholder=""/>
          </div>
          <div>
            <el-button size="large" class="modifyNameBtn" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </client-only>
<!--    换绑微信弹窗-->
    <change-we-chat :changeWeChat="changeBindingData" :key="changeBindingData"></change-we-chat>
  </div>
</template>

<script lang="ts" setup>
import {ElMessage} from "element-plus";
import {getUserInfo} from "../../api/apiconfig/main";
import ChangeWeChat from "./changeWeChat.vue";
const infoData = ref({
  avatar:'',
  groupId:'',
  user_id:'',
})
const changeBindingData = ref({value:""})
const userInfo = (async ()=>{
  const response = await getUserInfo()
  if(response.data.length!==0){
    infoData.value.groupId = response.data.group_id
    infoData.value.avatar = response.data.avatar
    infoData.value.user_id = response.data.user_id
  }
})
// 换绑微信
const getChangeBinding  =(()=>{
  changeBindingData.value = {value:true}
})
onMounted (()=>{
  userInfo()
})
const modifyNameValue = ref()
//修改昵称弹窗默认变量
const modifyNameModal = ref(false);
//昵称修改按钮触发方法
const getMOdifyName = (() => {
  modifyNameModal.value = true
})
</script>

<style lang="less" scoped>
.accountInfo {
  display: flex;
  flex-direction: column;
  padding: 0 40px;

  .avatarInfo {
    display: flex;

    .avatarTextSize {
      height: 90px;
    }

    .gradeTextSize {
      height: 79.19px;
    }

    .avatarText {
      width: 94.69px;
      display: flex;
      align-items: center;

      span {
        color: #333333;
        font-size: 16px;
      }
    }

    .avatarImg {
      width: 733.31px;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .avatarTitle {
        font-weight: 500;
        color: #000000;
        font-size: 16px;
      }

      .modify {
        cursor: pointer;
        line-height: 80px;
        font-size: 16px;
        color: #ff6644;
      }
    }
  }

  .money {
    width: 100%;
    height: 95px;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #DEDEDE;

    .line {
      width: 3px;
      height: 36px;
      background: #DEDEDE;
      margin-top: 18px;
    }
  }
}

.modalBox {
  display: flex;
  align-items: center;
  flex-direction: column;

  .modifyNameTitle {
    margin: 25px 0;
    width: 300px;
  }

  .modifyNameBtn {
    width: 300px;
    margin-bottom: 10px;
  }

}
</style>