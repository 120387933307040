<template>
  <client-only>
    <el-dialog align-center :show-close="false" :close-on-click-modal="false" :closable="false"
               class="changeWeChatDialog" style="width: 370px"
               footer-hide="false" @click="getClearTiming()" v-model="modal">
      <div class="changeWeChat">
        <div class="colse" @click="modal=false">
          <i style="color: #868686;font-size: 20px;cursor: pointer" class="iconfont icon-guanbi"></i>
        </div>
        <div class="changTitle">
          <i class="iconfont icon-weixin"></i> <span>使用换绑微信进行扫码</span>
        </div>
        <img class="weChatImage" :src="imgData" alt="">
        <div class="explain">
          <span>注: 换绑微信操作,会将账号所有数据,</span>
          <span>将移动到新账号,请谨慎操作!</span>
        </div>
      </div>
    </el-dialog>
  </client-only>
</template>

<script setup lang="ts">
import {ElMessage} from "element-plus";
import {getChangeBindingUserInfo, getWechatImage} from "../../api/apiconfig/main";
import {ref} from "vue";
import {setLocal} from "../../utils/local";

const imgData = ref('/img/qr-loading.gif');
const uniqid = ref()
const timingNewImg = ref()
const timing = ref()
const props = defineProps({
  changeWeChat: Object
})
const modal = ref(false)
//初始化
onMounted(() => {
  getModal()
});
const getModal = () => {
  if (props.changeWeChat.value) {
    modal.value = true;
    getWechatData()
  }
}
//获取登录二维码
const getWechatData = (async () => {
  //清空定时器
  if (timing.value) {
    clearInterval(timing.value)
  }
  if (timingNewImg.value) {
    clearInterval(timingNewImg.value)
  }
  const response = await getWechatImage({})
  if (response.data) {
    imgData.value = response.data.img
    uniqid.value = response.data.uniqid
    //每隔2秒获取一次状态
    timing.value = setInterval(() => {
      userinfo()
    }, 2000)
    //每隔30秒获取一次新的二维码
    timingNewImg.value = setInterval(() => {
      getWechatData()
    }, 30000)
    //定时器10分钟不在获取登录状态
    setTimeout(() => {
      clearInterval(timingNewImg.value)
      modal.value = false
    }, 600000)
  }
})
//获取换绑微信的状态
const userinfo = (async () => {
  const response = await getChangeBindingUserInfo({uniqid: uniqid.value})
  if (response) {
    if (response.data.token) {
      ElMessage.success(response.msg)
      setLocal("uid", response.data.uid, 1 / 2880)
      setLocal("token", response.data.token, 1 / 2880)
      getClearTiming()
      modal.value = false
    }else if(response.data.code!==0){
      ElMessage.error(response.msg)
      modal.value = false
      getClearTiming()
    }
  } else {
    getClearTiming()
  }
})
//清空定时器
const getClearTiming = (() => {
  clearInterval(timing.value)
  clearInterval(timingNewImg.value)
})
</script>
<style>
.changeWeChatDialog {
  --el-dialog-bg-color: unset !important;
  --el-dialog-box-shadow: unset !important;
}
</style>
<style lang="less" scoped>
.changeWeChat {
  border-radius: 6px;
  height: 350px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;

  .weChatImage {
    width: 70%;
  }

  .changTitle {
    display: flex;
    align-items: center;

    i {
      font-size: 30px;
      color: #3cd369;
    }

    span {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .explain {
    width: 70%;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 15px;

    span {
      margin-top: 3px;
      color: red;
    }
  }
}

.colse {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  position: absolute;
  top: 35px;
  right: -35px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    z-index: 1;
  }
}
</style>