<!--开票历史-->
<template>
  <div>
  <client-only>
    <el-table :data="tableData"  stripe style="width: 100%">
      <el-table-column align="center" prop="create_time" label="发票申请时间"/>
      <el-table-column align="center" show-overflow-tooltip prop="invoice_rise" label="发票抬头"/>
      <el-table-column align="center" prop="invoice_amount" label="开票金额(元)"/>
      <el-table-column align="center" show-overflow-tooltip prop="duty_paragraph" label="纳税识别号"/>

      <el-table-column fixed="right" label="发票状态" min-width="120">
        <template #default="scope">
          <div class="tableInvoiceStatus">
            <span :style="{color:scope.row.status==='开票完成'?'#16D46B':''}" >{{scope.row.status}}</span>
            <div  v-if="scope.row.status==='开票完成'" class="line"></div>
            <span :style="{color:scope.row.status==='开票完成'?'#16D46B':''}" class="download" @click="getDowen(scope.row)" v-if="scope.row.status==='开票完成'">下载发票</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="total>0" class="pagingBox">
      <!--            分页-->
      <el-pagination @change="getQueryParams" :page-size="pageSize" class="paging" small
                     background :total="total" layout="prev, pager, next">
      </el-pagination>
      <div >
        共有 <b>{{total }}</b> 条数据
      </div>
    </div>
  </client-only>
    <download-invoice :downLoadInvoice="dowenloadData" :key="dowenloadData"></download-invoice>
  </div>
</template>

<script lang="ts" setup>
import {getInvoiceList} from "../../api/apiconfig/main";
import DownloadInvoice from "./downloadInvoice.vue";
//分页总条数
const total = ref(0)
const pageNumber = ref(1)
const tableData = ref([])
const pageSize = ref(Number(0))
const dowenloadData =ref({value:''})
//分页换页
const getQueryParams = ((number) => {
  pageNumber.value = number
  invoiceList()
})
//开票历史列表(接口)
const invoiceList = (async ()=>{
  const response = await getInvoiceList({page:pageNumber.value})
  if(response.data.length!==0){
    tableData.value = response.data.list
    total.value = response.data.total
    pageSize.value = Number(response.data.pagesize)
  }else{
    tableData.value = []
  }
})
onMounted (()=>{
  invoiceList()
})
//下载发票
const  getDowen = ((row)=>{
  dowenloadData.value = {value:row}
})
</script>
<style lang="less" scoped>
.pagingBox{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  div{
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
  }
}
.tableInvoiceStatus{
  display: flex;
  align-items: center;
  user-select: none;
  .line{
    width: 2px;
    height: 15px;
    background: #606266;
    margin: 0 5px;
  }
  .download{
    cursor: pointer;
  }
}
</style>