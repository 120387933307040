<!--个人中心-->
<template>
  <div>
    <PanelHeader class="head"></PanelHeader>
  </div>
  <div style="overflow: auto;height: 100vh">
    <div class="box">
      <div class="tab" >
        <div class="tab-left">
          <div :style="menuShow === 1 ? menuTitleClo : ''" @click="handleqiehuan(1)"><span>账户信息</span></div>
          <div :style="menuShow === 3 ? menuTitleClo : ''" @click="handleqiehuan(3)"><span>我的订单</span></div>
          <div :style="menuShow === 4 ? menuTitleClo : ''" @click="handleqiehuan(4)"><span>申请发票</span></div>
          <div :style="menuShow === 5 ? menuTitleClo : ''" @click="handleqiehuan(5)"><span>开票历史</span></div>
        </div>
        <div class="tab-right" :style="{ height: menuShow == 4 ? '996px' : '600px' }">
          <!--       账户信息-->
          <account-information v-if="menuShow==1"></account-information>
          <!--        我的订单-->
          <my-order v-if="menuShow==3"></my-order>
          <!--        申请发票-->
            <apply-invoice v-if="menuShow==4"></apply-invoice>
<!--          开票历史-->
          <InvoicingHistory v-if="menuShow==5"></InvoicingHistory>
        </div>
      </div>
    </div>
    <div class="font">
      <PublicFooter></PublicFooter>
    </div>
  </div>
</template>

<script setup lang="ts">

import PanelHeader from "~/layout/components/PanelHeader.vue";
import {useRoute} from 'vue-router';
import PublicFooter from "/src/components/PublicFooter/index";
import AccountInformation from "./accountInformation.vue";
import MyOrder from "./myOrder.vue";
import ApplyInvoice from "./applyInvoice.vue";
import InvoicingHistory from "./InvoicingHistory.vue";

const handleqiehuan = (id:Number) =>{
  router.push('/personalCenter/id/'+id)
  menuShow.value = id;
}
const router = useRouter();
const route = useRoute();
//菜单显示默认变量
const menuShow = ref(4);
//备注input变量
const remarks = ref();
menuShow.value = Number(route.params.id)
//菜单鼠标点击颜色变化
const menuTitleClo = ref({
  borderLeft: "6px solid #ff6644",
  background: " rgba(121, 102, 255, 0.08)",
  color: "#ff6644"
})
onMounted(async () => {
  //console.log(menuShow.value,'menuShow.value')
})
</script>

<style scoped lang="less">
.head {
  position: fixed;
}

.font {
  margin-top: -30px;
}

.box {
  width: 100%;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  padding-top: 130px;
  border-bottom: 1px solid #ccc;

  .tab {
    width: 1200px;
    background: #f7f7f7;
    display: flex;
    justify-content: space-between;
    .tab-left {
      cursor: pointer;
      width: 252px;
      height: 600px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;

      div {
        width: 252px;
        height: 60px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .tab-right {
      width: 908px;
      background: #FFFFFF;
      margin-bottom: 100px;
    }
  }
}
</style>