<!--申请发票-->
<template>
  <div class="accountInfo"  v-loading.fullscreen.lock="fullscreenLoading">
    <div class="invoiceTitle">开票记录</div>
    <div class="money">
      <div class="moneyTotal moneyPublic">
        <div>总支付金额</div>
        <div class="moneyText">¥<span>{{amountMoney.totalMoney}}</span></div>
      </div>
      <div class="line"></div>
      <div class="moneyOpened moneyPublic">
        <div>已开票金额</div>
        <div class="moneyText">¥<span>{{amountMoney.invoicedAmount}}</span></div>
      </div>
      <div class="line"></div>
      <div class="moneysurplus moneyPublic">
        <div>剩余可开票金额</div>
        <div class="moneyText">¥<span>{{amountMoney.remainingAmount}}</span></div>
      </div>
    </div>
    <div class="invoiceTitle">开票须知</div>
    <div class="notice">1、如申请发票，开票金额满<span>{{limitAmount}}元</span>，数电发票出票后通过扫描二维码进行下载；<br>
      2、所申请发票为增值税普通发票；<br>
      3、确认所提交信息正确无误，由此造成的损失松鼠快图不承担责任；<br>
      4、提交发票申请后，约<span>7个工作日</span>为您出票，请耐心等待；<br>
      5、请保证信息真实性，否则无法为您开票；<br>
    </div>
    <div class="invoiceTitle">发票申请</div>
    <div class="application">
      <!-- <Form ref="formInvoiceApplication" :model="formInvoiceApplication" :rules="ruleInvoiceApplication" > -->
      <div class="applicationItem">
        <div class="title">发票金额：</div>
        <el-input v-model="formInvoiceApplication.money" placeholder="请填写发票金额"
                  style="width:400px"/>
      </div>
      <div class="applicationItem">
        <div class="title">发票类型：</div>
        <div class="content">普通电子发票</div>
      </div>
      <div class="applicationItem">
        <div class="title">发票抬头：</div>
        <!-- <FormItem  prop="invoiceHeader"> -->
        <div class="content">
          <el-input v-model="formInvoiceApplication.invoiceHeader" placeholder="请填写发票抬头"
                    style="width:400px"/>
        </div>
        <!-- </FormItem> -->
      </div>
      <div class="warning">请确保公司名字准确无误，抬头填写错误造成打印发票错误的不可重开。</div>
      <div class="applicationItem">
        <div class="title">开票项目：</div>
        <div class="content">信息技术服务费</div>
      </div>
      <div class="applicationItem">
        <div class="title">纳税人识别号：</div>
        <!-- <FormItem  prop="taxpayerNumber"> -->
        <div class="content">
          <el-input v-model="formInvoiceApplication.taxpayerNumber" placeholder="请填写纳税人识别号"
                    style="width:400px"/>
        </div>
        <!-- </FormItem> -->
      </div>
      <div class="warning">国家税务规定，企业必须填写“纳税人识别号/统一社会信用代码”，发票才能入账，否则是废票。<br>
        政府单位等没有税号的可填写事业法人登记证号。税号提供错误造成打印发票错误的不可重开。
      </div>
      <div class="warning">请确认邮箱地址准确无误，电子发票将发送至该邮箱。</div>
      <div class="applicationItem">
        <div class="title">发票备注：</div>
        <div class="content">
          <el-input v-model="formInvoiceApplication.remarks" type="textarea" style="width: 400px"
                    placeholder="请填写发票备注"/>
        </div>
      </div>
      <!-- </Form> -->
    </div>
    <div class="submitBox">
      <div class="submitBtn"><span @click="getInvoiceSubmit()">提交申请</span></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ElMessage} from 'element-plus';
import {getConfigLimit, getInvoiceRecords, getMyInvoice} from "../../api/apiconfig/main";
const fullscreenLoading = ref(false)
const amountMoney = reactive({
  totalMoney:Number(0.00),
  invoicedAmount:Number(0.00),
  remainingAmount:Number(0.00)
})
const limitAmount = ref(0)
//发票申请form表单变量
const formInvoiceApplication = reactive({
  invoiceHeader: '',
  taxpayerNumber: '',
  remarks: '',
  money:'',
});
//发票提交申请
const getInvoiceSubmit = (() => {
  if (formInvoiceApplication.invoiceHeader && formInvoiceApplication.taxpayerNumber && formInvoiceApplication.money) {
    if(formInvoiceApplication.money <= amountMoney.remainingAmount){
      if(formInvoiceApplication.money >=  Number(limitAmount.value)){
        myInvoice()
      }else{
        ElMessage({
          message: '未达到开票最小金额',
          type: 'warning',
        })
      }
    } else{
      ElMessage({
        message: '您目前的消费金额不足'+formInvoiceApplication.money+'元',
        type: 'warning',
      })
    }
  } else if (!formInvoiceApplication.invoiceHeader) {
    ElMessage({
      message: '请填写发票抬头',
      type: 'warning',
    })
  } else if (!formInvoiceApplication.taxpayerNumber) {
    ElMessage({
      message: '请填写纳税人识别号',
      type: 'warning',
    })
  } else if (!formInvoiceApplication.money) {
    ElMessage({
      message: '请填写发票金额',
      type: 'warning',
    })
  }
})
const myInvoice = (async ()=>{
  fullscreenLoading.value = true
  const response = await getMyInvoice({
    invoice_rise:formInvoiceApplication.invoiceHeader,
    duty_paragraph:formInvoiceApplication.taxpayerNumber,
    invoice_amount:formInvoiceApplication.money,
  })
  if(response.code===1){
    fullscreenLoading.value = false
    ElMessage.success(response.msg)
    invoiceRecords()
    formInvoiceApplication.invoiceHeader = '';
    formInvoiceApplication.taxpayerNumber = '';
    formInvoiceApplication.remarks = '';
    formInvoiceApplication.money = '';
  }else{
    fullscreenLoading.value = false
    ElMessage.error(response.msg)
  }
})
//获取发票限制(接口)
const configLimit = (async ()=>{
  const response = await getConfigLimit()
  if(response.data.length!==0){
    limitAmount.value = Number(response.data.invoice_price_limit)
  }
})
//获取发票数据(接口)
const invoiceRecords = (async ()=>{
  const response = await getInvoiceRecords()
  if(response.data.length!==0){
    amountMoney.totalMoney  = response.data.order_money
    amountMoney.invoicedAmount = response.data.have_invoice_money
    amountMoney.remainingAmount = response.data.residue
  }
})
onMounted (()=>{
  configLimit()
  invoiceRecords()
})
</script>

<style lang="less" scoped>
.accountInfo {
  display: flex;
  flex-direction: column;
  padding: 0 40px;

  .invoiceTitle {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
  }

  .money {
    width: 100%;
    height: 95px;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #DEDEDE;

    .moneyText {
      margin-top: 15px;
    }

    .line {
      width: 3px;
      height: 36px;
      background: #DEDEDE;
      margin-top: 18px;
    }

    .moneyPublic {
      height: 78px;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }

      span {
        font-size: 40px;
        color: #e54b4b;
        margin-top: 50px;
      }
    }

    .moneyTotal {
      width: 240px;
    }

    .moneyOpened {
      width: 310px;
    }

    .moneysurplus {
      width: 256px;

    }
  }

  .notice {
    width: 100%;
    height: 156px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    border-bottom: 3px solid #DEDEDE;

    span {
      color: #e54b4b;
    }
  }

  .application {
    width: 100%;
    height: 470px;

    .applicationItem {
      width: 828px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .title {
        width: 98px;
        height: 20px;
        line-height: 20px;
        text-align: right;
        font-weight: 400;
        color: #333333;
        margin-right: 20px;
        font-size: 14px;
      }

      .content {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }

    .warning {
      margin-top: -13px;
      margin-left: 118px;
      font-size: 12px;
      color: #e54b4b;
      font-weight: 400;
      margin-bottom: 15px;
    }
  }

  .submitBox {
    width: 100%;
    display: flex;
    justify-content: center;

    .submitBtn {
      cursor: pointer;
      width: 200px;
      height: 40px;
      background-color: #FF6644;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
    }
  }
}
</style>