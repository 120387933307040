<!--我的订单-->
<template>
    <client-only>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" show-overflow-tooltip prop="jpm_order" label="订单ID"/>
        <el-table-column align="center" show-overflow-tooltip prop="pro_name" label="订单内容"/>
        <el-table-column align="center" show-overflow-tooltip prop="pay_time" label="订单生成时间"/>
        <el-table-column align="center" prop="price" label="订单金额(元)"/>
        <el-table-column align="center"  prop="pay_type" label="支付方式"/>
      </el-table>
      <div v-show="total>0" class="pagingBox">
        <!--            分页-->
        <el-pagination @change="getQueryParams" :page-size="pageSize" class="paging" small
                         background :total="total" layout="prev, pager, next">
        </el-pagination>
<!--        <div >-->
<!--          共有 <b>{{total }}</b> 条数据-->
<!--        </div>-->
      </div>
    </client-only>
</template>

<script lang="ts" setup>
import {getUserOrderList} from "../../api/apiconfig/main";
//分页总条数
const total = ref(0)
const pageNumber = ref(1)
const tableData = ref([])
const pageSize = ref(Number(0))
//分页换页
const getQueryParams = ((number) => {
  pageNumber.value = number
  userOrderList()
})
const userOrderList = (async ()=>{
  const response = await getUserOrderList({page:pageNumber.value})
  if(response.data.length!==0){
    tableData.value = response.data.list
    total.value = response.data.total
    pageSize.value = Number(response.data.pagesize)
  }else{
    tableData.value = []
  }
})
onMounted (()=>{
  userOrderList()
})
</script>

<style lang="less" scoped>
.pagingBox{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  div{
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>