<!--下载发票-->
<template>
  <client-only>
    <el-dialog align-center :show-close="false" :close-on-click-modal="false" :closable="false"
               class="downDialog" style="width: 370px"
               footer-hide="false" v-model="modal">
      <div class="changeWeChat">
        <div class="colse" @click="modal=false">
          <i style="color: #868686;font-size: 20px;cursor: pointer" class="iconfont icon-guanbi"></i>
        </div>
        <div class="changTitle">
         <span>请扫描下方二维码进行下载</span>
        </div>
        <img class="weChatImage" :src="imgData.imgSrc" alt="">
        <div class="explain">
            <span>发票号码:<span>{{imgData.invoiceNumber}}</span></span>
          <span>购方名称:<span>{{imgData.buyerSName}}</span></span>
         <span>价税合计(元):<span>¥ {{imgData.totalPrice}}</span></span>
        </div>
      </div>
    </el-dialog>
  </client-only>
</template>

<script setup lang="ts">
import {ElMessage} from "element-plus";
import {ref} from "vue";

const imgData = ref({
  imgSrc:"/img/qr-loading.gif",
  invoiceNumber:"",
  buyerSName:"",
  totalPrice:"",
})
const props = defineProps({
  downLoadInvoice: Object
})
const modal = ref(false)
//初始化
onMounted(() => {
  getModal()
});
const getModal = () => {
  if (props.downLoadInvoice.value) {
    imgData.value.imgSrc = props.downLoadInvoice.value.qrcode
    imgData.value.invoiceNumber = props.downLoadInvoice.value.duty_paragraph
    imgData.value.buyerSName = props.downLoadInvoice.value.invoice_rise
    imgData.value.totalPrice = props.downLoadInvoice.value.invoice_amount
    modal.value = true;
  }
}
</script>
<style>
.downDialog {
  --el-dialog-bg-color: unset !important;
  --el-dialog-box-shadow: unset !important;
}
</style>
<style lang="less" scoped>
.changeWeChat {
  border-radius: 6px;
  height: 350px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;

  .weChatImage {
    width: 70%;
    height: 200.59px;
  }

  .changTitle {
    display: flex;
    align-items: center;

    i {
      font-size: 30px;
      color: #3cd369;
    }

    span {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .explain {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 15px;

    span {
      margin-top: 3px;
      color: #33383e;
    }
  }
}

.colse {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  position: absolute;
  top: 35px;
  right: -35px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    z-index: 1;
  }
}
</style>